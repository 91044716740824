{
    "name": "ffcsplanner",
    "version": "2.0.0",
    "lastUpdate": {
        "vellore": "All Semester",
        "chennai": "All Semester"
    },
    "repository": "https://github.com/sarveshdakhore/FFCSonTheGo.git",
    "author": "Sarvesh Dakhore <dmtosarvesh@gmail.com>, Vatsal Joshi <vatz88@gmail.com>",
    "license": "GPL-3.0",
    "private": true,
    "scripts": {
        "start": "yarn convert && yarn start:noconvert",
        "start:noconvert": "parcel src/index.html",
        "build:sw": "workbox generateSW ./src/workbox-config.js",
        "build": "yarn convert && parcel build src/index.html && yarn build:sw",
        "convert": "node util/convert_xlsx_to_json.js && node util/convert_json_to_data.js",
        "test": "jest tests",
        "prepare": "husky install"
    },
    "engines": {
        "node": "^20"
    },
    "browserslist": "> 0.5%, last 2 versions, not dead",
    "dependencies": {
        "@fortawesome/fontawesome-free": "^6.4.2",
        "@parcel/core": "^2.10.1",
        "@parcel/packager-raw-url": "2.10.3",
        "@parcel/transformer-sass": "2.10.3",
        "@parcel/transformer-webmanifest": "2.10.3",
        "@popperjs/core": "^2.11.8",
        "@sentry/browser": "^7.76.0",
        "bootstrap": "^5.3.2",
        "bootstrap-select": "^1.13.18",
        "bootstrap4": "npm:bootstrap@4.6.0",
        "date-fns": "^2.30.0",
        "easy-autocomplete": "1.3.5",
        "firebase": "^11.0.1",
        "html2canvas": "^1.4.1",
        "jquery": "3.7.1",
        "localforage": "^1.10.0",
        "parcel": "^2.10.1",
        "sass": "^1.69.5",
        "workbox-cli": "^7.0.0",
        "xlsx": "^0.18.5"
    },
    "devDependencies": {
        "husky": "^8.0.0",
        "jest": "^29.7.0",
        "prettier": "^2.8.8",
        "pretty-quick": "^3.1.3",
        "process": "^0.11.10"
    }
}
